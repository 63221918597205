import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import OtherNews from "../sections/blogDetail/OtherNews"
import NewsDetail from "../sections/blogDetail/NewsDetail"
import { Paper } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingBottom: "30px",
        backgroundColor: "#EAEAEA",
        transition: "all .3s ease-in-out",
    },
    paper1: {
        backgroundColor: "#EAEAEA",
        '@media screen and (min-width: 1025px)': {
            width: "85%",
            margin: "0 auto",
        },
    }
}));

export default function BlogDetai({ data, otherNews }) {
    const classes = useStyles();

 
    return <Paper className={classes.paper}>
        <Paper elevation="0" className={classes.paper1}>
            <NewsDetail data={data} />
            <OtherNews data={otherNews} />
        </Paper>
    </Paper>
}