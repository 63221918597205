import React from "react"
import { graphql } from "gatsby"
import withRoot from "../../components/shell/withRoot"
import BlogDetail from "../../components/blog/pageContents/BlogDetail"
import SEO from "../../components/seo"

function IndexPage({ data }) {
  let tagsData = []
  data.blogJson.tags.forEach(item => {
    data.allBlogJson.nodes.forEach(i => {
      if (i.tags.indexOf(item) !== -1) {
        if (tagsData.indexOf(i) === -1) {
          tagsData.push(i)
        }
      }
    })
  })
  const imgSrc = data.blogJson.img.src.absolutePath.split("/")
  const imgName = imgSrc[imgSrc.length - 1]
  const img = require("../../data/blog/media/" + imgName)
  return (
    <React.StrictMode>
      <SEO title="Tin tức" img={img} description={data.blogJson.news} />
      <BlogDetail data={data.blogJson} otherNews={data.allBlogJson.nodes} />
    </React.StrictMode>
  )
}

export default withRoot(IndexPage)
export const query = graphql`
  query($id: String) {
    blogJson(id: { eq: $id }) {
      author
      tags
      content
      date
      id
      news
      summary
      title
      img {
        alt
        src {
          absolutePath
          childImageSharp {
            fluid {
              srcSet
            }
          }
        }
      }
    }
    allBlogJson(
      filter: { id: { ne: $id } }
      sort: { fields: order, order: DESC }
    ) {
      nodes {
        date
        tags
        title
        news
        id
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        summary
        content
        author
      }
    }
  }
`
