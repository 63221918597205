import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import CardOther from "./CardOther";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "40px",
  },
  title: {
    backgroundColor: "#61A0FF",
    color: "#ffffff",
    padding: "6px 0px 4px 10px",
    position: "relative",
    fontSize: "18px",
    "@media screen and (min-width: 768px)": {
      fontSize: "28px",
      padding: "3px 0px 1px 40px",
    },
    "&:before": {
      content: `''`,
      position: "absolute",
      right: "-1px",
      top: "-1px",
      width: "50%",
      borderTop: "36px solid #EAEAEA",
      borderLeft: "36px solid transparent",
      "@media screen and (min-width: 450px)": {
        width: "60%",
      },
      "@media screen and (min-width: 525px)": {
        width: "70%",
      },
      "@media screen and (min-width: 768px)": {
        width: "60%",
        borderTop: "46px solid #EAEAEA",
        borderLeft: "46px solid transparent",
      },
      "@media screen and (min-width: 987px)": {
        width: "67%",
      },
    },
  },
  container1: {
    padding:"30px 0",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    "&:last-child": {
      paddingBottom: "30px",
    },
  },
  button: {
    textAlign: "center",
  },
  buttonchill: {
    fontSize: "18px",
    margin: "10px 0px 20px",
    "@media screen and (max-width: 767px)": {
      fontSize: "14px",
    },
  },

}));

export default function OtherNews({ data}) {
  const classes = useStyles();
  const [visibleSize, setVisibleSize] = useState(3);

  const ShowMoreItems = () => {
    setVisibleSize((preValue) => preValue + 3);
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h6" component="h1" className={classes.title}>
        Bài viết liên quan
      </Typography>
      <div className={classes.container1}>
        {data.slice(0, visibleSize).map((news, id) => {
          return <CardOther key={id} data={news} />;
        })}
      </div>
      {visibleSize < data.length ? (
        <div div className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            onClick={ShowMoreItems}
            className={classes.buttonchill}
          >
            Read more
          </Button>
        </div>
      ) : null}
    </Container>
  );
}
