import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import Image from "../../../common/Image";
import CardIcon from "../../common/CardIcon"
import { CardContent } from "@material-ui/core";
import Alink from "../../../common/ALink"
const useStyles = makeStyles(() => ({
  bigcontent: {
    margin: "24px 10px 8px 10px",
    width: "270px",
    '@media screen and (min-width: 1300px)': {
      maxWidth: 300,
    },
  },
  bloglink: {
    textDecoration: "none",
  },
  img: {
    height: 170,
    width: "100%",
    objectFit: "cover",
    "& img": {
      position: "inherit !important"
    },
    '@media screen and (min-width: 1300px)': {
      height: 200,
    },
  },
  content: {
    display: "-webkit-box",
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: "3",
    paddingTop: "0px",
  },
  title: {
    color: "#488BE9",
    fontSize: "14px",
    padding: "16px",
    height: "85px",
  },
  link: {
    textDecoration: "none",
  },
}));
export default function CardOther(data) {
  const classes = useStyles();
  const path = `/blog/${data.data.id}`

  return (
    <Card className={classes.bigcontent}>
      <Alink to={path} className={classes.link}>
        <CardActionArea >
          <Image
            className={classes.img}
            {...data.data.img}
          />
          <CardContent className={classes.content}>
            <Typography className={classes.title} variant="h6" component="h6">
              {data.data.news}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Alink>
      <CardIcon id={data.data.id} news={data.data.news} />
    </Card>
  )
}
