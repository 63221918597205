import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import { Container, Paper } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MarkdownViewer from "../../../common/MarkdownViewer";
import ALink from "../../../common/ALink";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import CardIcon from "../../common/CardIcon";
import LeftTime from "../../common/LeftTime";
import { getViewCountByBlogNewsId, addOrUpdateViewCounter } from "../../../../api/blog.service"
import { useState } from "react";
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';


const useStyles = makeStyles((theme) => ({
  container: {
    "@media screen and (max-width: 767px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  allArticlesLink: {
    textDecoration: "none",
    display: "flex",
    padding: "20px 0",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0",
    }
  },
  allArticlesDesc: {
    textTransform: "none",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    color: "#488BE9",
    padding: "15px 0",
    "@media screen and (max-width: 767px)": {
      padding: "20px 0 10px",
    },
    transition: "all .3s linear",
    "&:hover": {
      fontWeight: "500",
    },
  },
  allArticlesLinkIcon: {
    marginTop: "13.5px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginTop: "8px",
    },
  },
  allArticlesLinkText: {
    color: theme.palette.primary.main,
  },
  contentdetail: {
    padding: "5%",
    [theme.breakpoints.down("xs")]: {
      boxShadow: "none",
    },
  },
  title: {
    color: "#1f65b7",
    marginBottom: "1rem",
    fontSize: "20px",
    "@media screen and (min-width: 768px)": {
      padding: "0 1rem",
      fontSize: "25px",
    },
    "@media screen and (min-width: 1024px)": {
      fontSize: "30px",
    },
  },
  newsInfo: {
    display: "flex",
    color: "#0000008a",
    marginBottom: "1rem",
    paddingLeft: "10px",
    "@media screen and (min-width: 768px)": {
      padding: "0 1rem",
    },
  },
  authorDate: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    paddingTop: "2px",
    marginRight: "2rem",
    fontWeight: "500",
    color: "#4a4a4a8a",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  content: {
    paddingBottom: "30px",
    "& img": {
      padding: "20px 0",
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  tagName: {
    textDecoration: "none",
    display: "inline-block",
    padding: "4px 16px",
    backgroundColor: "#ccc",
    borderRadius: "30px",
    color: "#333",
    fontStyle: "italic",
    margin: "0 4px",
    "&:hover": {
      opacity: 0.7
    }
  },
  tags: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    color: "#488BE9",
  },
  tagIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
  }
}));
export default function NewsDetail({ data }) {
  const classes = useStyles();
  const [newCount, setNewCount] = useState()
  useEffect(() => {
    getViewCountByBlogNewsId(data.id).then(result => {
      addOrUpdateViewCounter(data.news, data.id, result.count + 1, result.likeUser,)
      setNewCount(result.count + 1)
    });
  }, [data.id]);
  return (
    <Container className={classes.container}>
      <ALink to="/blog" className={classes.allArticlesLink} >
        <KeyboardArrowLeftIcon className={classes.allArticlesLinkIcon} />
        <p className={classes.allArticlesLinkText}>Tất cả bài viết</p>
      </ALink>
      <Paper className={classes.contentdetail}>
        <div>
          <Typography className={classes.title} variant="h5" >
            {data.news}
          </Typography>
          <div className={classes.newsInfo}>
            <Typography className={classes.authorDate} variant="h5">
              <PersonIcon /> {data.author}
            </Typography>
            <Typography className={classes.authorDate} variant="h5">
              <AccessTimeIcon />
              <LeftTime time={data.date} />
            </Typography>
          </div>
          <p align="justify" className={classes.content}>
            <MarkdownViewer data={data.content} folderSrc="blog" />
          </p>
          <CardIcon id={data.id} newCount={newCount} news={data.news} />
        </div>
        <div className={classes.tags}>
          <div className={classes.tagIcon}>
            <LocalOfferRoundedIcon className={classes.icon} style={{ width: "16px", height: "16px" }} />
            <p>Tags :</p>
          </div>
          <div>
            {data.tags.map(tag => {
              return <ALink 
              to={`/blog/tag/${tag.normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/đ/g, 'd')
              .replace(/Đ/g, 'D')
              .replace(/ /g, '-')
              .toLowerCase()}`} 
              className={classes.tagName}>{tag}
              </ALink>
            })}
          </div>
        </div>

      </Paper>
    </Container>
  );
}
